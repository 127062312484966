.Container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.Table {
  display: grid;
  grid-template-columns: 190px repeat(7, 60px);
  grid-template-rows: repeat(7, 36px);
  cursor: default;
  border: 1px solid #ddd;
  grid-gap: 1px;
  background-color: #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.Title {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  height: 32px;
}

.Header {
  display: flex;
}

.Header span {
  font-size: 12px;
  display: flex;
  height: 22px;
  align-items: center;
  width: 61px;
  justify-content: center;
  cursor: default;
}

.Header span:first-child {
  width: 190px;
}

.Cell {
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
}

.Row {
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: 14px;
  padding: 0 10px;
}

.Checkbox {
  transform: translateX(3px);
}
