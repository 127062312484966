.Container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ebebeb;
  width: 270px;
  justify-content: space-between;
  min-width: 270px;
}

.Body {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  height: 0;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 0;
}

.Body > div:first-of-type {
  transform: translateX(24px);
}

.Footer {
  border-top: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 24px;
}

.Button {
  align-self: center;
  color: #007f39;
}
