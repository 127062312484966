.Container {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  border: 1px solid #ebebeb;
  transform: translateX(-1px);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  align-self: flex-start;
}

.DeleteButton,
.RadioGroup {
  margin-top: 24px;
}
