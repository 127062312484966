.Container {
  display: grid;
  grid-template-rows: 70px auto;
  flex: 1 1 auto;
  min-height: 100%;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Actions {
  display: flex;
  gap: 10px;
}

.Title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #252c2e;
}

.Body {
  display: grid;
  grid-template-columns: 240px auto;
}

.Form {
  padding: 12px;
  border: 1px solid #ebebeb;
  transform: translateX(-1px);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 24px;
  align-items: center;
  grid-gap: 12px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 24px;
}
