.Container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.Header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;
}

.Form {
  display: grid;
  grid-template-columns: 240px 1fr;
  align-self: flex-start;
  width: 100%;
}

.Controls {
  border: 1px solid #ebebeb;
  transform: translateX(-1px);
  padding: 16px 24px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Row {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.SubRow {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.DayOfMonth {
  max-width: 154px;
}

.Days {
  display: flex;
  gap: 10px;
}

.Comment {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
}

.Placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: #252c2e;
}
