.Controls {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.Actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
