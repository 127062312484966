.Container {
  align-self: flex-start;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 24px;
  justify-content: flex-start;
  padding: 0;
  gap: 5px;
}

.Button:active {
  transform: translateY(1px);
}

.ColorDefault {
  color: #818c99;
}

.ColorGreen {
  color: #007f39;
}
