.Container {
  display: grid;
  grid-template-columns: 180px 1fr;
  height: 0;
  flex: 1 1 auto;
  overflow-y: auto;
}

.Form {
  display: flex;
  padding: 20px;
  flex-direction: column;
  border: 1px solid #ebebeb;
  transform: translateX(-1px);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  align-self: flex-start;
  min-height: 100%;
  gap: 16px;
}

.Tabs {
  margin-top: 24px;
}

.Row {
  display: flex;
  justify-content: space-between;
}

.Placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: #252c2e;
}
