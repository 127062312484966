.Container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 12px;
  border-bottom: 1px solid #ebebeb;
}

.Container div {
  transform: translateX(0);
}
