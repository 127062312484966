.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 210px;
  width: 75vw;
  border-left: 1px solid #ebebeb;
}

.Icon {
  color: #4e5d60;
}

.Message {
  color: #252c2e;
  width: 385px;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  font-weight: 400;
}

.Create {
  color: #007f39;
  cursor: pointer;
}
