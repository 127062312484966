.Container {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  flex: 1 1 auto;
}

.Header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
}

.Gateways {
  display: flex;
  flex-direction: column;
  height: 0;
  flex: 1 1 auto;
}

.GatewaysProps {
  display: grid;
  grid-template-columns: 240px 1fr;
  height: 0;
  flex: 1 1 auto;
  overflow-y: auto;
}

.FeedProps {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 222px 222px 222px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 24px;
}

.Actions {
  display: flex;
  gap: 10px;
}

.Title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  color: #252c2e;
}
