.AddButton {
  font-weight: 500;
  font-size: 15px;
  padding: 0;
  border-radius: 0;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: rgb(0 127 57);
  cursor: pointer;
  flex-shrink: 0;
}

.AddButton img {
  margin-right: 8px;
}
