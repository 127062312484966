.Container {
  display: grid;
  flex-direction: column;
  padding: 0 24px;
  flex: 1 1 auto;
  min-height: 100%;
  overflow-y: auto;
  grid-template-rows: 75px 86px 170px auto auto;
}

.Header {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 70px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
}

.Actions {
  display: flex;
  gap: 10px;
}

.Title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  color: #252c2e;
}

.SubTitle {
  font-size: 20px;
  font-weight: 400;
  color: #252c2e;
}

.Loader {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.Loader > div {
  top: unset;
  left: unset;
  position: unset;
}

.Main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 38px;
}

.Identifiers {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 38px 0;
  border-bottom: 1px solid #ebebeb;
}

.IdentifiersForm {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
}

.List {
  display: flex;
  flex-direction: column;
}

.ListHeader {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}

.ListBody {
  display: flex;
  flex-direction: column;
}

.ListForm {
  transform: translateX(-1px);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ListFormRow {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 50px;
  align-items: center;
}

.ListTitle {
  font-size: 20px;
  font-weight: 400;
  color: #252c2e;
}

.List:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid #ebebeb;
}

.Sections {
  border-bottom: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-bottom: 24px;
}

.SectionsProps {
  display: grid;
  grid-template-columns: 240px 1fr;
  flex: 1 1 auto;
  overflow-y: auto;
}

.DeleteButton {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  transform: translateY(13px);
}
