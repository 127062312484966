.Container {
  display: flex;
  align-items: center;
  border: 1px solid rgb(242 241 241);
  border-radius: 4px;
  overflow: hidden;
  align-self: flex-start;
}

.Item {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  color: rgb(0 127 57);
  outline: 0;
  border: 0;
  background-color: transparent;
}

.Item:not(:last-child) {
  border-right: 1px solid rgb(242 241 241);
}

.Item:hover {
  background-color: rgb(247 247 247);
  cursor: pointer;
}

.Active {
  background-color: rgb(247 247 247);
  color: #252c2e;
}
