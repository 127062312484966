.Controls {
  display: flex;
  gap: 8px;
  align-items: center;
}

.Control {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
}

.PanelGroup {
  margin-top: 20px;
  grid-template-columns: 2fr minmax(550px, 1fr);
}

.AddField {
  margin-bottom: 32px;
}
