.Container {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  flex: 1 1 auto;
}

.Header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
}

.Actions {
  display: flex;
  gap: 10px;
}

.Title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  color: #252c2e;
}

.Rules {
  display: flex;
  flex-direction: column;
  height: 0;
  flex: 1 1 auto;
  border-top: 1px solid #ebebeb;
}

.Loader {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.Loader > div {
  top: unset;
  left: unset;
  position: unset;
}
