.Form {
  visibility: hidden;
}

.Opened {
  visibility: visible;
}

.InputContainer {
  margin-bottom: 10px;
}

.Footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.Actions {
  display: flex;
  align-items: center;
}

.Actions > .SaveButton {
  margin-right: 24px;
}
