.Container {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #fff7e8;
  justify-content: space-between;
  min-width: 370px;
  min-height: 56px;
  gap: 24px;
  color: #252c2e;
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
}

.Actions {
  display: flex;
  gap: 5px;
}
