.Wrapper {
  background-color: #fff;
}

.Head {
  border-bottom: 1px solid #ebebeb;
}

.Head > div {
  margin-bottom: 10px;
}

.PanelGroup {
  grid-gap: 0 !important;
}

.TableWrapper {
  overflow-y: scroll;
  max-height: calc(100vh - 201px);
}

.Hidden {
  visibility: hidden;
}

.RemoveButtonContainer {
  visibility: hidden;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 200px;
}

.Table [role='row']:hover .RemoveButtonContainer {
  visibility: visible;
}

.LoaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
