.Container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: #fff;
  height: calc(100vh - 70px);
}

.Header {
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  border-bottom: 1px solid #ebebeb;
  height: 96px;
  flex: 0 0 auto;
}

.Body {
  display: flex;
  height: 0;
  flex: 1 1 auto;
}
