.Wrapper {
  background-color: #fff;
}

.Head {
  border-bottom: 1px solid #ebebeb;
}

.Head > div {
  margin-bottom: 10px;
}

.Content {
  display: flex;
}

.SelectedListBlock {
  display: flex;
  flex-direction: column;
  width: 270px;
  min-width: 270px;
  justify-content: space-between;
  max-height: calc(100vh - 201px);
}

.Panel {
  overflow-y: auto !important;
  padding: 24px 0 !important;
  overflow-x: hidden;
  margin-bottom: 24px;
}

.Panel > div:first-of-type {
  transform: translateX(24px);
}
