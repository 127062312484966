.Controls {
  display: flex;
  gap: 16px;
  align-items: center;
}

.PanelGroup {
  margin-top: 20px;
  grid-template-columns: 2fr minmax(550px, 1fr);
}

.Image {
  padding-top: 15px;
}
